<template>
  <v-container class="justify-center">
    <VideoUpload
      v-model="editDialog"
      :refresh="fetchVideo"
      :editing="true"
      :video="video"
    />

    <v-row>
      <v-col
        :cols="12"
        :md="4"
      >
        <video
          controls
          :src="video.preview_url || video.file_url"
          width="400px"
        />
      </v-col>

      <v-col
        :cols="12"
        :md="8"

      >
        <p class="display-3 pl-3">
          {{ video.name }}
        </p>
        <p class="headline">
          {{ video.description }}
        </p>
      </v-col>
    </v-row>

    <v-dialog
      v-model="deleteDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Are you sure you want to delete this video?
        </v-card-title>

        <v-card-text>
          This action cannot be reverted.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="green darken-1"
            text
            @click="deleteDialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="deleteVideo"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-speed-dial
      v-model="fab"
      fixed
      bottom
      right
      direction="top"
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="blue darken-2"
          dark
          fab
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-video-edit
          </v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        small
        color="green"
        @click.stop="editDialog = true"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>

      <v-btn
        fab
        dark
        small
        color="red"
        @click.stop="deleteDialog = true"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import axios from 'axios';
import VideoUpload from "@/components/dialogs/VideoUpload.vue";
import { bus } from "@/plugins/events";

export default {
  components: {
    VideoUpload
  },

  data: () => ({
    fab: false,
    deleteDialog: false,
    editDialog: false,

    video: {}
  }),

  mounted() {
    this.fetchVideo();
  },

  methods: {
    fetchVideo() {
      axios.get(`/v1/videos/${this.$route.params.id}/info`).then((response) => {
        this.video = response.data.video;
      }).catch(() => {
        this.$router.go(-1);
      });
    },

    deleteVideo() {
      axios.delete(`/v1/videos/${this.$route.params.id}`).then(() => {
        this.$router.replace('/videos');
        bus.$emit('show-snack', {
          text: "Video was deleted!",
          color: "success"
        });
      }).catch(() => {
        bus.$emit('show-snack', {
          text: "Couldn't delete this video!",
          color: "error"
        });
      });
    }
  }
}
</script>

<style>

</style>
